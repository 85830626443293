<template>
  <div class="space-y-4">
    <offline-action-alert v-if="true" />
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card v-if="!showAddMoveInItem && !showEditMoveInItem">
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field
          :label="'Is an existing Order'"
          :state="inputStates('formData.isExistingOrder')"
          required
        >
          <asom-input-checkbox
            :disabled="!isOnline"
            v-model="formData.isExistingOrder"
            label="Is existing Order"
            description="Check if the moving in items are from an Order"
          />
        </asom-form-field>
        <asom-form-field
          v-if="showOrderSelection"
          :label="'Order'"
          required
          :state="inputStates('formData.order')"
          error="Order is required"
        >
          <asom-input-select
            :state="inputStates('formData.order')"
            v-model="formData.order"
            :options="orderOptions"
            placeholder="Type to search for Orders"
          />
        </asom-form-field>
        <div class="col-span-2 pt-4">
          <article
            v-if="checkListShow"
            class="bg-gray-100 rounded-md pl-4 pr-4 pb-4"
          >
            <div
              v-for="(item, index) in formData.orderItems"
              :key="index"
              class="pt-4"
            >
              <asom-card :title="item.inventoryTypeName">
                <template v-slot:header>
                  <div class="flex justify-between">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{ item.inventoryTypeName }}
                    </h3>
                    <div>
                      <button
                        type="button"
                        @click="removeItem(item.inventoryTypeId)"
                      >
                        <asom-icon icon="close"></asom-icon>
                      </button>
                    </div>
                  </div>
                </template>
                <div class="space-y-4">
                  <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                    <asom-form-field label="Quantity">
                      <span class="mt-3">{{ get(item, "amount", "-") }}</span>
                    </asom-form-field>
                    <asom-form-field label="Inventory Group">
                      <span class="mt-3">{{
                        get(item, "inventoryGroup", "-")
                      }}</span>
                    </asom-form-field>
                    <asom-form-field
                      v-if="get(item, 'expiryDate', false)"
                      label="Expiry Date"
                    >
                      <span class="mt-3">{{
                        formatDate(get(item, "expiryDate", "-"))
                      }}</span> </asom-form-field
                    ><asom-form-field label="Primary Location">
                      <span class="mt-3">{{
                        get(item, "primaryLocationName", "-")
                      }}</span> </asom-form-field
                    ><asom-form-field
                      v-if="get(item, 'secondaryLocationName', false)"
                      label="Secondary Location"
                    >
                      <span class="mt-3">{{
                        get(item, "secondaryLocationName", "-")
                      }}</span> </asom-form-field
                    ><asom-form-field label="Remarks">
                      <span class="mt-3">{{ get(item, "remarks", "-") }}</span>
                    </asom-form-field>
                  </div>
                </div>
                <div class="col-span-2 flex justify-end space-x-4 pt-8">
                  <asom-button text="Edit" @click="editItem(item, index)" />
                </div>
              </asom-card>
            </div>
          </article>
          <div
            v-if="formData.order !== null && isItemsLoading"
            class="text-center"
          >
            <asom-icon icon="spinner" class="animate-spin" />
          </div>
        </div>
        <asom-form-field
          class="col-span-2"
          label="Supporting documents"
          :state="inputStates('formData.files')"
        >
          <asom-upload
            v-model="formData.files"
            :category="attachmentCategories.INVENTORY"
            :state="inputStates('formData.files')"
          />
        </asom-form-field>
        <asom-form-field
          label="Remarks"
          required
          :state="inputStates('formData.remarks')"
          error="Remarks is required"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4 pt-8">
          <asom-button
            text="Back"
            variant="secondary"
            @click="$router.go(-1)"
          />
          <asom-button
            v-if="!formData.isExistingOrder"
            text="Add Item"
            @click="addItemClicked"
            :loading="isSubmitting"
          />
          <asom-button
            text="Confirm Receipt"
            @click="confirmClicked"
            :loading="isSubmitting"
          />
        </div></form
    ></asom-card>
    <add-move-in-item
      v-model="showAddMoveInItem"
      @addedItem="addedItem($event)"
    ></add-move-in-item>
    <edit-move-in-item
      :key="reRenderVariable"
      v-model="showEditMoveInItem"
      :selected-item="selectedItemForEdit"
      @addedItem="updateItem($event)"
    ></edit-move-in-item>
  </div>
</template>

<script>
import get from "lodash.get";
import remove from "lodash.remove";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  getInventoryOrderDetails,
  moveInItems,
} from "../../../../services/inventory.service";
import AddMoveInItem from "./AddMoveInItem";
import EditMoveInItem from "./EditMoveInItem";
import { add } from "../../../../helpers/numbers";
import OfflineActionAlert from "@/layout/OfflineActionAlert";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  components: {
    AddMoveInItem,
    EditMoveInItem,
    OfflineActionAlert,
  },
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: null,
      showOrderSelection: false,
      formData: {
        isExistingOrder: false,
        order: null,
        orderItems: [],
        files: [],
        remarks: null,
      },
      selectedItemForEdit: null,
      isItemsLoading: false,
      showAddMoveInItem: false,
      showEditMoveInItem: false,
      reRenderVariable: 0,
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      isOnline: "apiStatus/isInventoryModuleOnline",
      orderOptions: "inventoryManagement/order/orderOptions",
    }),
    checkListShow() {
      if (
        (!this.isItemsLoading &&
          this.formData.order !== null &&
          this.formData.isExistingOrder) ||
        (!this.formData.isExistingOrder &&
          !this.isItemsLoading &&
          this.formData.orderItems.length > 0)
      )
        return true;
      else return false;
    },
    submissionData() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }

      return {
        inventoryOrderId: get(this.formData.order, "value"),
        inventoryOrderNo: get(this.formData.order, "label"),
        isManuallyEntered: !this.formData.isExistingOrder,
        stationId: this.stationId,
        lineId: this.lineId,
        remarks: this.formData.remarks,
        receivedOrder: this.formData.orderItems,
        attachmentId: get(uploadedIds, "0", null),
      };
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  watch: {
    "formData.isExistingOrder": function (newValue) {
      if (newValue) {
        this.showOrderSelection = true;
      } else {
        this.showOrderSelection = false;
      }
    },
    "formData.order": function (newValue) {
      this.getOrderDetails(get(newValue, "value"));
    },
  },
  validations() {
    let validations = {
      formData: {
        isExistingOrder: { required },
        remarks: { required },
      },
    };
    if (this.formData.isExistingOrder) {
      validations.formData.order = { required };
    }
    return validations;
  },
  mounted() {
    let orderId = get(this.$route.query, "orderId", null);
    if (orderId !== null) {
      this.formData.isExistingOrder = true;
      this.formData.order = {
        label: get(this.$route.query, "orderNo", null),
        value: orderId,
      };
      this.getOrderDetails(orderId);
    }
    if (!this.isOnline) this.formData.isExistingOrder = true;
  },
  methods: {
    get,
    formatDate(date) {
      if (date !== "-") return displayUtcDate(date);
      else return date;
    },
    async getOrderDetails(orderId) {
      if (this.isOnline) {
        this.isItemsLoading = true;
        const result = await getInventoryOrderDetails({
          inventoryOrderId: orderId,
        });
        if (result.success) {
          this.isItemsLoading = false;
          this.formData.orderItems = get(result.payload, "data.orderItems");
        } else {
          this.isItemsLoading = false;
          this.error = result.payload;
        }
      } else {
        const orderItems =
          this.$store.getters["inventoryManagement/order/orderItems"](orderId);
        this.formData.orderItems = orderItems.map(
          ({
            inventoryOrderId,
            inventoryTypeId,
            inventoryGroupEnum,
            inventoryGroup,
            inventoryTypeName,
            amount,
            remarks,
            requiredPrimaryLocation = true,
            requiredSecondaryLocation = true,
            requiredExpiredDate = true,
          }) => ({
            inventoryOrderId,
            inventoryTypeId,
            inventoryGroupEnum,
            inventoryGroup,
            inventoryTypeName,
            amount,
            remarks,
            requiredPrimaryLocation,
            requiredSecondaryLocation,
            requiredExpiredDate,
          })
        );
      }
    },
    removeItem(itemId) {
      remove(this.formData.orderItems, { inventoryTypeId: itemId });
    },
    editItem(item, index) {
      item.index = index;
      this.selectedItemForEdit = item;
      this.showEditMoveInItem = true;
      this.reRenderVariable = this.reRenderVariable + 1;
    },
    addItemClicked() {
      this.error = null;
      this.showAddMoveInItem = true;
    },
    addedItem(item) {
      this.addToList(item);
    },
    updateItem(item) {
      this.formData.orderItems[this.selectedItemForEdit.index] = item;
    },
    addToList(item) {
      if (this.formData.orderItems.length > 0) {
        this.formData.orderItems.forEach((existingItem, index) => {
          if (
            get(item, "inventoryTypeId", null) ==
              get(existingItem, "inventoryTypeId", null) &&
            (get(item, "primaryLocationId", null) ==
              get(existingItem, "primaryLocationId", null) ||
              (get(existingItem, "primaryLocationId", null) == null &&
                get(existingItem, "secondaryLocationName", null) == null)) &&
            (get(item, "secondaryLocationName", null) ==
              get(existingItem, "secondaryLocationName", null) ||
              (get(existingItem, "primaryLocationId", null) == null &&
                get(existingItem, "secondaryLocationName", null) == null)) &&
            (get(item, "expiryDate", null) ==
              get(existingItem, "expiryDate", null) ||
              get(existingItem, "expiryDate", null) == null)
          ) {
            let newQuantity = add(
              parseInt(existingItem.amount),
              parseInt(item.amount)
            );
            this.formData.orderItems[index] = item;
            this.formData.orderItems[index].amount = newQuantity;
          } else if (index == this.formData.orderItems.length - 1) {
            this.formData.orderItems.push(item);
          }
        });
      } else {
        this.formData.orderItems.push(item);
      }
    },
    confirmClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        if (this.formData.orderItems.length > 0) this.onSubmit();
        else {
          this.error = "Please Add Items";
          this.$scrollTop();
        }
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    onSubmit() {
      if (this.isOnline) {
        this.moveInItemAsync();
      } else {
        this.$store.dispatch("offlineData/moveInItems", this.submissionData);
        this.showModal = false;
        this.$router.push({ name: "Order Main Page" });
      }
    },
    async moveInItemAsync() {
      this.isSubmitting = true;
      const result = await moveInItems(this.submissionData);
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
