<template>
  <div class="space-y-4" v-if="showAddMoveInItem">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
          <asom-form-field
            class="col-span-2"
            :label="'Inventory Group'"
            :state="inputStates('formData.inventoryGroup')"
            error="Inventory Group is required"
            required
          >
            <asom-input-select
              :state="inputStates('formData.inventoryGroup')"
              v-model="formData.inventoryGroup"
              :options="inventoryGroups"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2 w-auto"
            :label="'Item Type'"
            required
            :state="inputStates('formData.inventoryType')"
            error="Item Type is required"
          >
            <!-- <div class="flex items-start space-x-4"> -->
            <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4">
              <div>
                <asom-input-select
                  :state="inputStates('formData.inventoryType')"
                  v-model="formData.inventoryType"
                  :loading="isLoadingInventoryTypes"
                  :options="inventoryTypeOptions"
                  @search-change="queryInventoryTypes"
                  placeholder="Type to search for Item Type"
                />
              </div>
              <div>
                <asom-q-r-code-scanner
                  @detect="qrCodeResult"
                  class="items-start"
                >
                </asom-q-r-code-scanner>
              </div>
            </div>
          </asom-form-field>
          <asom-form-field
            v-if="isFirstAidInventoryType"
            class="col-span-2"
            :label="'First Aid Box'"
            required
            :state="inputStates('formData.firstAidBox')"
            error="First Aid Box is required"
          >
            <asom-input-select
              :state="inputStates('formData.firstAidBox')"
              v-model="formData.firstAidBox"
              :loading="isLoadingFirstAidBoxes"
              :options="firstAidBoxOptions"
              @search-change="queryFirstAidBoxes"
              placeholder="Type to search for First Aid Box"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            :label="'Primary Location'"
            required
            :state="inputStates('formData.primaryLocation')"
            error="Primary Location is required"
          >
            <asom-input-select
              :state="inputStates('formData.primaryLocation')"
              v-model="formData.primaryLocation"
              :loading="isLoadingPrimaryLocations"
              :options="primaryLocationOptions"
              @search-change="queryPrimaryLocations"
              :disabled="isFirstAidInventoryType"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="isSecondaryLocationRequired"
            :label="'Secondary Location'"
            :required="isSecondaryLocationRequired"
            :state="inputStates('formData.secondaryLocation')"
            error="Secondary Location is required"
          >
            <asom-input-text
              type="text"
              v-model="formData.secondaryLocation"
              :state="inputStates('formData.secondaryLocation')"
              :disabled="isFirstAidInventoryType"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Quantity"
            required
            :state="inputStates('formData.quantity')"
            error="Quantity is required"
          >
            <asom-input-text
              type="number"
              v-model="formData.quantity"
              :state="inputStates('formData.quantity')"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="isExpiryDateRequired"
            label="Expiry Date"
            :required="isExpiryDateRequired"
            :state="inputStates('formData.expiryDate')"
            error="Expiry Date is required"
          >
            <asom-input-date
              :min-date="new Date()"
              v-model="formData.expiryDate"
              :state="inputStates('formData.expiryDate')"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Remarks"
            required
            :state="inputStates('formData.remarks')"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <div class="col-span-4 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Cancel"
              variant="secondary"
              @click="backClicked"
            />
            <asom-button
              text="Add"
              @click="addItemClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import { useVuelidate } from "@vuelidate/core";
import {
  getListOfInventoryTypes,
  getListOfInventoryLocations,
  getInventoryTypeDetails,
  getListOfFirstAidBoxes,
} from "../../../../services/inventory.service";

export default {
  name: "AddMoveInItem",
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "addedItem"],
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      showAddMoveInItem: false,
      inventoryTypeOptions: [],
      primaryLocationOptions: [],
      firstAidBoxOptions: [],
      isLoadingInventoryTypes: false,
      isLoadingPrimaryLocations: false,
      error: null,
      isSubmitting: false,
      formData: {
        inventoryGroup: null,
        firstAidBox: null,
        inventoryType: null,
        primaryLocation: null,
        secondaryLocation: null,
        quantity: null,
        expiryDate: null,
        remarks: null,
      },
      isLoadingFirstAidBoxes: false,
      selectedInventoryType: null,
      isLoadingTypeDetails: false,
    };
  },
  validations() {
    let validations = {
      formData: {
        inventoryGroup: { required },
        inventoryType: { required },
        primaryLocation: { required },
        quantity: { required },
        remarks: { required },
      },
    };

    if (this.isSecondaryLocationRequired) {
      validations.formData.secondaryLocation = { required };
    }

    if (this.isExpiryDateRequired) {
      validations.formData.expiryDate = { required };
    }

    if (this.isFirstAidInventoryType) {
      validations.formData.firstAidBox = { required };
    }
    return validations;
  },
  mounted() {
    this.showAddMoveInItem = this.modelValue;
  },
  watch: {
    modelValue(newVal) {
      this.showAddMoveInItem = newVal;
      this.formData = {};
      this.firstAidBoxOptions = [];
      this.primaryLocationOptions = [];
      this.inventoryTypeOptions = [];
      this.v$.formData.$reset();
    },
    "formData.inventoryType": function (newValue, prevValue) {
      if (
        get(prevValue, "value") !== get(newValue, "value") &&
        newValue !== null
      ) {
        this.getTypeDetails(get(newValue, "value"));
      }
    },
    "formData.inventoryGroup": function (newValue, prevValue) {
      if (
        get(prevValue, "value") !== get(newValue, "value") &&
        !this.isLoadingTypeDetails
      ) {
        this.queryInventoryTypes("", true);
        this.formData.inventoryType = null;
        this.isLoadingTypeDetails = false;
        // this.$refs.itemTypeSelection.clearDropdownOptions();
      } else this.isLoadingTypeDetails = false;
    },
    "formData.firstAidBox": function (newValue, prevValue) {
      if (get(prevValue, "value") !== get(newValue, "value")) {
        if (
          get(newValue, "primaryLocationName") &&
          get(newValue, "primaryLocationId")
        ) {
          this.formData.primaryLocation = {
            label: get(newValue, "primaryLocationName"),
            value: get(newValue, "primaryLocationId"),
          };
        } else {
          this.formData.primaryLocation = null;
        }
        this.formData.secondaryLocation = get(
          newValue,
          "secondaryLocationName"
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
    isSecondaryLocationRequired() {
      return (
        get(this.formData.inventoryType, "requiredSecondaryLocation", false) ||
        this.isFirstAidInventoryType
      );
    },
    isExpiryDateRequired() {
      return get(this.formData.inventoryType, "requiredExpiredDate", false);
    },
    isFirstAidInventoryType() {
      return (
        get(this.formData.inventoryGroup, "value") ==
        InventoryGroups.FIRST_AID.VALUE
      );
    },
  },
  methods: {
    get,
    async queryFirstAidBoxes(query) {
      this.error = null;
      this.isLoadingFirstAidBoxes = true;
      if (!query) {
        this.isLoadingFirstAidBoxes = false;
        this.firstAidBoxOptions = [];
      }
      const result = await getListOfFirstAidBoxes({
        stationId: this.stationId,
        lineId: this.lineId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingFirstAidBoxes = false;
        this.error = null;
        this.firstAidBoxOptions = get(result, "payload.list", []).map(
          ({
            inventoryFirstAidBoxId,
            name,
            primaryLocationName,
            primaryLocationId,
            secondaryLocationName,
          }) => ({
            label: name,
            value: inventoryFirstAidBoxId,
            primaryLocationName: primaryLocationName,
            primaryLocationId: primaryLocationId,
            secondaryLocationName: secondaryLocationName,
          })
        );
      } else {
        this.isLoadingFirstAidBoxes = false;
        this.error = result.payload;
        this.firstAidBoxOptions = [];
      }
    },
    qrCodeResult(data) {
      let itemId = data;
      this.getTypeDetails(itemId);
    },
    async getTypeDetails(itemId) {
      this.error = null;
      this.isLoadingTypeDetails = true;
      const result = await getInventoryTypeDetails({
        inventoryTypeId: itemId,
        stationId: this.stationId,
      });
      if (result.success) {
        let itemDetails = get(result.payload, "inventoryTypeDetailsViewModel");
        this.selectedInventoryType = itemDetails;
        this.formData.inventoryGroup = {
          label: get(itemDetails, "inventoryGroup"),
          value: get(itemDetails, "inventoryGroupEnum"),
        };
        this.formData.inventoryType = {
          label: get(itemDetails, "inventoryTypeName"),
          value: get(itemDetails, "inventoryTypeId"),
          requiredPrimaryLocation: get(
            itemDetails,
            "requiredPrimaryLocation",
            false
          ),
          requiredSecondaryLocation: get(
            itemDetails,
            "requiredSecondaryLocation",
            false
          ),
          requiredExpiredDate: get(itemDetails, "requiredExpiredDate", false),
        };
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    backClicked() {
      this.$emit("update:modelValue", false);
    },
    async queryInventoryTypes(query) {
      this.error = null;
      this.isLoadingInventoryTypes = true;
      if (!query) {
        this.isLoadingInventoryTypes = false;
        this.inventoryTypeOptions = [];
      }
      const result = await getListOfInventoryTypes({
        stationId: this.stationId,
        inventoryGroup: get(this.formData.inventoryGroup, "value", null),
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingInventoryTypes = false;
        this.error = null;
        this.inventoryTypeOptions = get(result, "payload.list", []).map(
          ({
            inventoryTypeId,
            inventoryTypeName,
            requiredPrimaryLocation,
            requiredSecondaryLocation,
            requiredExpiredDate,
          }) => ({
            label: inventoryTypeName,
            value: inventoryTypeId,
            requiredPrimaryLocation,
            requiredSecondaryLocation,
            requiredExpiredDate,
          })
        );
      } else {
        this.isLoadingInventoryTypes = false;
        this.error = result.payload;
        this.inventoryTypeOptions = [];
        this.$scrollTop();
      }
    },
    async queryPrimaryLocations(query) {
      this.error = null;
      this.isLoadingPrimaryLocations = true;
      if (!query) {
        this.isLoadingPrimaryLocations = false;
        this.primaryLocationOptions = [];
      }
      const result = await getListOfInventoryLocations({
        stationId: this.stationId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingPrimaryLocations = false;
        this.error = null;
        this.primaryLocationOptions = get(result, "payload.list", []).map(
          ({ inventoryLocationId, locationName }) => ({
            label: locationName,
            value: inventoryLocationId,
          })
        );
      } else {
        this.isLoadingPrimaryLocations = false;
        this.error = result.payload;
        this.primaryLocationOptions = [];
        this.$scrollTop();
      }
    },
    addItemClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let selectedTypeDetails = {
        inventoryGroupEnum: get(this.formData.inventoryGroup, "value"),
        inventoryGroup: get(this.formData.inventoryGroup, "label"),
        firstAidBoxId: get(this.formData.firstAidBox, "value"),
        firstAidBoxName: get(this.formData.firstAidBox, "label"),
        inventoryTypeId: get(this.formData.inventoryType, "value"),
        inventoryTypeName: get(this.formData.inventoryType, "label"),
        amount: this.formData.quantity,
        expiryDate: this.formData.expiryDate,
        primaryLocationId: get(this.formData.primaryLocation, "value"),
        primaryLocationName: get(this.formData.primaryLocation, "label"),
        secondaryLocationName: this.formData.secondaryLocation,
        remarks: this.formData.remarks,
      };
      this.$emit("addedItem", selectedTypeDetails);
      this.$emit("update:modelValue", false);
    },
  },
};
</script>
