<template>
  <div class="space-y-4" v-if="showEditMoveInItem">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            :label="'Inventory Group'"
            :state="inputStates('formData.inventoryGroup')"
            error="Inventory Group is required"
            required
          >
            <asom-input-select
              :state="inputStates('formData.inventoryGroup')"
              v-model="formData.inventoryGroup"
              :options="inventoryGroups"
              disabled
            />
          </asom-form-field>

          <asom-form-field
            :label="'Inventory Type'"
            required
            :state="inputStates('formData.inventoryType')"
            error="Inventory Type is required"
          >
            <asom-input-select
              :state="inputStates('formData.inventoryType')"
              v-model="formData.inventoryType"
              :loading="isLoadingInventoryTypes"
              :options="inventoryTypeOptions"
              @search-change="queryInventoryTypes"
              disabled
              placeholder="Type to search for Inventory Type"
            />
          </asom-form-field>

          <template v-if="isFirstAidInventoryType">
            <asom-form-field
              :label="'First Aid Box'"
              required
              :state="inputStates('formData.firstAidBox')"
              error="First Aid Box is required"
            >
              <asom-input-select
                v-if="isOnline"
                :state="inputStates('formData.firstAidBox')"
                v-model="formData.firstAidBox"
                :loading="isLoadingFirstAidBoxes"
                :options="firstAidBoxOptions"
                @search-change="queryFirstAidBoxes"
                placeholder="Type to search for First Aid Box"
              />
              <asom-input-select
                v-else
                :state="inputStates('formData.firstAidBox')"
                v-model="formData.firstAidBox"
                :options="offlineFirstAidBoxOptions"
              />
            </asom-form-field>
            <div></div>
          </template>
          <asom-form-field
            :label="'Primary Location'"
            required
            :state="inputStates('formData.primaryLocation')"
            error="Primary Location is required"
          >
            <asom-input-select
              v-if="isOnline"
              :state="inputStates('formData.primaryLocation')"
              v-model="formData.primaryLocation"
              :loading="isLoadingPrimaryLocations"
              :options="primaryLocationOptions"
              @search-change="queryPrimaryLocations"
              :disabled="isFirstAidInventoryType"
            />
            <asom-input-select
              v-else
              :state="inputStates('formData.primaryLocation')"
              v-model="formData.primaryLocation"
              :options="offlinePrimaryLocationOptions"
              :disabled="isFirstAidInventoryType"
            />
          </asom-form-field>
          <asom-form-field
            :label="'Secondary Location'"
            v-if="isSecondaryLocationRequired"
            :required="isSecondaryLocationRequired"
            :state="inputStates('formData.secondaryLocation')"
            error="Secondary Location is required"
          >
            <asom-input-text
              type="text"
              v-model="formData.secondaryLocation"
              :state="inputStates('formData.secondaryLocation')"
              :disabled="isFirstAidInventoryType"
            />
          </asom-form-field>
          <asom-form-field
            label="Quantity"
            required
            :state="inputStates('formData.quantity')"
            error="Quantity is required"
          >
            <asom-input-text
              type="number"
              v-model="formData.quantity"
              :state="inputStates('formData.quantity')"
            />
          </asom-form-field>
          <asom-form-field
            label="Expiry Date"
            v-if="isExpiryDateRequired"
            :required="isExpiryDateRequired"
            :state="inputStates('formData.expiryDate')"
            error="Expiry Date is required"
          >
            <asom-input-date
              :min-date="new Date()"
              v-model="formData.expiryDate"
              :state="inputStates('formData.expiryDate')"
            />
          </asom-form-field>
          <asom-form-field
            label="Remarks"
            required
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Cancel"
              variant="secondary"
              @click="backClicked"
            />
            <asom-button
              text="Update"
              @click="addItemClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import { useVuelidate } from "@vuelidate/core";
import {
  getListOfInventoryTypes,
  getListOfInventoryLocations,
  getListOfFirstAidBoxes,
  getInventoryTypeDetails,
} from "../../../../services/inventory.service";

export default {
  name: "EditMoveInItem",
  props: {
    selectedItem: {
      type: undefined,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "addedItem"],
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      showEditMoveInItem: false,
      inventoryTypeOptions: [],
      primaryLocationOptions: [],
      firstAidBoxOptions: [],
      isLoadingInventoryTypes: false,
      isLoadingPrimaryLocations: false,
      error: null,
      isSubmitting: false,
      formData: {
        inventoryGroup: null,
        firstAidBox: null,
        inventoryType: null,
        primaryLocation: null,
        secondaryLocation: null,
        quantity: null,
        expiryDate: null,
        remarks: null,
      },
      isLoadingFirstAidBoxes: false,
      isSecondaryLocationRequired: false,
      isExpiryDateRequired: false,
      isFirstAidInventoryType: false,
    };
  },
  validations() {
    let validations = {
      formData: {
        inventoryGroup: { required },
        inventoryType: { required },
        primaryLocation: { required },
        quantity: { required },
        remarks: { required },
      },
    };

    if (this.isSecondaryLocationRequired) {
      validations.formData.secondaryLocation = { required };
    }

    if (this.isExpiryDateRequired) {
      validations.formData.expiryDate = { required };
    }

    if (this.isFirstAidInventoryType) {
      validations.formData.firstAidBox = { required };
    }
    return validations;
  },
  mounted() {
    this.getTypeDetails(get(this.selectedItem, "inventoryTypeId"));
    this.showEditMoveInItem = this.modelValue;
    this.isSecondaryLocationRequired = get(this.selectedItem, "requiredSecondaryLocation", true);
    this.isExpiryDateRequired = get(this.selectedItem, "requiredExpiredDate", true);
  },
  watch: {
    modelValue(newVal) {
      this.showEditMoveInItem = newVal;
      if (newVal)
        this.getTypeDetails(get(this.selectedItem, "inventoryTypeId"));
    },
    selectedItem() {
      if (
        get(this.selectedItem, "inventoryGroup") &&
        get(this.selectedItem, "inventoryGroupEnum")
      ) {
        this.formData.inventoryGroup = {
          label: get(this.selectedItem, "inventoryGroup"),
          value: get(this.selectedItem, "inventoryGroupEnum"),
        };
      } else {
        this.formData.inventoryGroup = null;
      }
      if (
        get(this.selectedItem, "firstAidBoxName") &&
        get(this.selectedItem, "firstAidBoxId")
      ) {
        this.formData.firstAidBox = {
          label: get(this.selectedItem, "firstAidBoxName"),
          value: get(this.selectedItem, "firstAidBoxId"),
        };
      } else {
        this.formData.firstAidBox = null;
      }
      if (
        get(this.selectedItem, "inventoryTypeName") &&
        get(this.selectedItem, "inventoryTypeId")
      ) {
        this.formData.inventoryType = {
          label: get(this.selectedItem, "inventoryTypeName"),
          value: get(this.selectedItem, "inventoryTypeId"),
        };
      } else {
        this.formData.inventoryType = null;
      }
      this.formData.quantity = get(this.selectedItem, "amount");
      if (
        get(this.selectedItem, "primaryLocationName") &&
        get(this.selectedItem, "primaryLocationId")
      ) {
        this.formData.primaryLocation = {
          label: get(this.selectedItem, "primaryLocationName"),
          value: get(this.selectedItem, "primaryLocationId"),
        };
      } else {
        this.formData.primaryLocation = null;
      }

      this.formData.secondaryLocation = get(
        this.selectedItem,
        "secondaryLocationName"
      );
      this.formData.expiryDate = get(this.selectedItem, "expiryDate");
      this.formData.remarks = get(this.selectedItem, "remarks");
      this.isSecondaryLocationRequired = get(this.selectedItem, "requiredSecondaryLocation", true);
      this.isExpiryDateRequired = get(this.selectedItem, "requiredExpiredDate", true);
    },
    "formData.firstAidBox": function (newValue, prevValue) {
      if (get(prevValue, "value") !== get(newValue, "value")) {
        if (
          get(newValue, "primaryLocationName") &&
          get(newValue, "primaryLocationId")
        ) {
          this.formData.primaryLocation = {
            label: get(newValue, "primaryLocationName"),
            value: get(newValue, "primaryLocationId"),
          };
        } else {
          this.formData.primaryLocation = null;
        }
        this.formData.secondaryLocation = get(
          newValue,
          "secondaryLocationName"
        );
      }
    },
    "formData.inventoryType": function(){
      this.isSecondaryLocationRequired = get(this.formData.inventoryType, "requiredSecondaryLocation", false) ||
        this.isFirstAidInventoryType;
      this.isExpiryDateRequired = get(this.formData.inventoryType, "requiredExpiredDate", false);
      this.isFirstAidInventoryType = get(this.formData.inventoryGroup, "value") ==
        InventoryGroups.FIRST_AID.VALUE;
    },
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      isOnline: "apiStatus/isInventoryModuleOnline",
      offlineFirstAidBoxOptions: "inventoryManagement/firstAidBox/options",
      offlinePrimaryLocationOptions: "inventoryManagement/location/options",
    }),
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
  },
  methods: {
    get,
    backClicked() {
      this.$emit("update:modelValue", false);
    },
    async getTypeDetails(itemId) {
      this.formData.quantity = get(this.selectedItem, "amount", 0);
      if (this.isOnline && itemId) {
        const result = await getInventoryTypeDetails({
          inventoryTypeId: itemId,
          stationId: this.stationId,
        });
        if (result.success) {
          let itemDetails = get(
            result.payload,
            "inventoryTypeDetailsViewModel"
          );
          this.selectedInventoryType = itemDetails;
          this.formData.inventoryGroup = {
            label: get(itemDetails, "inventoryGroup"),
            value: get(itemDetails, "inventoryGroupEnum"),
          };
          this.formData.inventoryType = {
            label: get(itemDetails, "inventoryTypeName"),
            value: get(itemDetails, "inventoryTypeId"),
            requiredPrimaryLocation: get(
              itemDetails,
              "requiredPrimaryLocation",
              false
            ),
            requiredSecondaryLocation: get(
              itemDetails,
              "requiredSecondaryLocation",
              false
            ),
            requiredExpiredDate: get(itemDetails, "requiredExpiredDate", false),
          };
        } else {
          this.error = result.payload;
        }
      } else {
        this.formData.inventoryType = {
          label: get(this.selectedItem, "inventoryTypeName"),
          value: get(this.selectedItem, "inventoryTypeId"),
          requiredPrimaryLocation: get(
            this.selectedItem,
            "requiredPrimaryLocation",
            true
          ),
          requiredSecondaryLocation: get(
            this.selectedItem,
            "requiredSecondaryLocation",
            true
          ),
          requiredExpiredDate: get(
            this.selectedItem,
            "requiredExpiredDate",
            true
          ),
        };
        this.formData.inventoryGroup = {
          label: get(this.selectedItem, "inventoryGroup"),
          value: get(this.selectedItem, "inventoryGroupEnum"),
        };
      }
    },
    async queryFirstAidBoxes(query) {
      this.error = null;
      this.isLoadingFirstAidBoxes = true;
      if (!query) {
        this.isLoadingFirstAidBoxes = false;
        this.firstAidBoxOptions = [];
      }
      const result = await getListOfFirstAidBoxes({
        stationId: this.stationId,
        search: query.trim(),
        lineId: this.lineId,
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingFirstAidBoxes = false;
        this.error = null;
        this.firstAidBoxOptions = get(result, "payload.list", []).map(
          ({
            inventoryFirstAidBoxId,
            name,
            primaryLocationId,
            primaryLocationName,
            secondaryLocationName,
          }) => ({
            label: name,
            value: inventoryFirstAidBoxId,
            primaryLocationId,
            primaryLocationName,
            secondaryLocationName,
          })
        );
      } else {
        this.isLoadingFirstAidBoxes = false;
        this.error = result.payload;
        this.firstAidBoxOptions = [];
      }
    },
    async queryInventoryTypes(query) {
      this.error = null;
      this.isLoadingInventoryTypes = true;
      if (!query) {
        this.isLoadingInventoryTypes = false;
        this.inventoryTypeOptions = [];
      }
      const result = await getListOfInventoryTypes({
        stationId: this.stationId,
        inventoryGroup: get(this.formData.inventoryGroup, "value", null),
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingInventoryTypes = false;
        this.error = null;
        this.inventoryTypeOptions = get(result, "payload.list", []).map(
          ({
            inventoryTypeId,
            inventoryTypeName,
            requiredPrimaryLocation,
            requiredSecondaryLocation,
            requiredExpiredDate,
          }) => ({
            label: inventoryTypeName,
            value: inventoryTypeId,
            requiredPrimaryLocation,
            requiredSecondaryLocation,
            requiredExpiredDate,
          })
        );
      } else {
        this.isLoadingInventoryTypes = false;
        this.error = result.payload;
        this.inventoryTypeOptions = [];
      }
    },
    async queryPrimaryLocations(query) {
      this.error = null;
      this.isLoadingPrimaryLocations = true;
      if (!query) {
        this.isLoadingPrimaryLocations = false;
        this.primaryLocationOptions = [];
      }
      const result = await getListOfInventoryLocations({
        stationId: this.stationId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingPrimaryLocations = false;
        this.error = null;
        this.primaryLocationOptions = get(result, "payload.list", []).map(
          ({ inventoryLocationId, locationName }) => ({
            label: locationName,
            value: inventoryLocationId,
          })
        );
      } else {
        this.isLoadingPrimaryLocations = false;
        this.error = result.payload;
        this.primaryLocationOptions = [];
      }
    },
    addItemClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let selectedTypeDetails = {
        inventoryGroupEnum: get(this.formData.inventoryGroup, "value"),
        inventoryGroup: get(this.formData.inventoryGroup, "label"),
        firstAidBoxId: get(this.formData.firstAidBox, "value"),
        firstAidBoxName: get(this.formData.firstAidBox, "label"),
        inventoryTypeId: get(this.formData.inventoryType, "value"),
        inventoryTypeName: get(this.formData.inventoryType, "label"),
        amount: this.formData.quantity,
        expiryDate: this.formData.expiryDate,
        primaryLocationId: get(this.formData.primaryLocation, "value"),
        primaryLocationName: get(this.formData.primaryLocation, "label"),
        secondaryLocationName: this.formData.secondaryLocation,
        remarks: this.formData.remarks,
      };
      this.$emit("addedItem", selectedTypeDetails);
      this.$emit("update:modelValue", false);
      this.error = "";
      this.v$.formData.$reset();
    },
  },
};
</script>
